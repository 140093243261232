<template>
<div @keydown="onPress" @keyup="onRelease" tabindex="0">
<transition name="fade">
<div v-if="!isLoaded" class="loading" ref="loader">
        <div class="loader">
            Loading...
            <div class="pi pi-spin pi-spinner" style="fontSize: 3rem">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</transition>
<div>
	<div class="p-grid p-fluid dashboard" :class="{ hide2: !isLoaded}" >
		<div class="p-col-12">
			<InlineMessage v-if="errorMessage">{{errorMessage}}</InlineMessage>
		</div>
		<div class="p-col-12 p-lg-6">
			<Panel header="Calendar">
				<FullCalendar :events="events" :options="options" ref="deskCalendar" />
			</Panel>
		</div>
		<div class="p-col-12 p-lg-6">
			<component header="Map" :is="changeMap()" :visible="displayFullScreenMap" :modal="false" :showHeader="false">
				<div class="p-grid p-justify-even">
					<h3 class="p-col-12 p-d-flex p-jc-center">{{lastDaySelected}}</h3>
					<div class="img-overlay-wrap">

					<img src="assets/layout/images/DOGOffice.webp"/>
					<i class="pi p-mr-3" :class="fullscreenIcon" style="font-size: 3rem; color:white; cursor: pointer; z-index: 10;" @click="showHideFullScreen();" :style="fullscreenIconStyle" />
					<svg viewBox="0 0 2969 1670">
						<!--Desk 1 -->
						<rect x=1633 y=380 width="160" height="72" style="fill:rgb(51, 156, 79, 0.5); z-index:5;" ref="desk1" data-desk="1"/>
							<!-- <text id="dropShadowBlack" x=1633 y=380 fill="white" font-size="50px">1</text> -->
							<foreignObject x=1633 y=390 width="160" height="72">	
							<AvatarGroup class="p-mb-3" v-if="getAvatars(1)">
								<Avatar v-for="img in getAvatars(1).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(1).total > 3" :label="'+' + getAvatars(1).plus" shape="circle" size="" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1633 y=380 width="160" height="72" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk1)'/>
						<!--Desk 2 -->
						<rect x=1793 y=380 width="160" height="72" style="fill:rgb(51, 156, 79, 0.5)" ref="desk2" data-desk="2"/>
							<!-- <text id="dropShadowBlack" x=1800 y=380 fill="white" font-size="50px">Desk 2</text> -->
							<foreignObject x=1795 y=390 width="160" height="72">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(2)">
								<Avatar v-for="img in getAvatars(2).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(2).total > 3" :label="'+' + getAvatars(2).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1793 y=380 width="160" height="72" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk2)'/>
						<!--Desk 3 -->
						<rect x=1633 y=476 width="160" height="75" style="fill:rgb(51, 156, 79, 0.5)" ref="desk3" data-desk="3"/>
							<!-- <text x=1633 y=590 font-size="50px">Desk 3</text> -->
							<foreignObject x=1633 y=490 width="160" height="75">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(3)">
								<Avatar v-for="img in getAvatars(3).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(3).total > 3" :label="'+' + getAvatars(3).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1633 y=476 width="160" height="75" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk3)'/>
						<!--Desk 4 -->
						<rect x=1793 y=476 width="160" height="75" style="fill:rgb(51, 156, 79, 0.5)" ref="desk4" data-desk="4"/>
							<!-- <text x=1800 y=590 font-size="50px">Desk 4</text> -->
							<foreignObject x=1795 y=490 width="160" height="75">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(4)">
								<Avatar v-for="img in getAvatars(4).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(4).total > 3" :label="'+' + getAvatars(4).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<rect x=1793 y=476 width="160" height="75" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk4)'/>
					<!--Desk 5 -->
						<svg x=1055 y=165>
							<path d="M 186.219 651.7665 L 303.537 651.7665 L 364.9892 547.4839 L 294.226 506.5157 C 297.9504 510.2401 279.3285 577.2789 186.219 573.5545 L 186.219 573.5545 Z" style="fill:rgb(51, 156, 79, 0.5)" ref="desk5" data-desk="5" @click='svgClick($refs.desk5)' />
							<!-- <text id="dropShadowBlack" x=10 y=620 font-size="50px" fill="white">Desk 5</text> -->
							<foreignObject x=195 y=590 width="85" height="48">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(5)">
								<Avatar v-for="img in getAvatars(5).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(5).total > 3" :label="'+' + getAvatars(5).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 186.219 651.7665 L 303.537 651.7665 L 364.9892 547.4839 L 294.226 506.5157 C 297.9504 510.2401 279.3285 577.2789 186.219 573.5545 L 186.219 573.5545 Z" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk5)' />
						</svg>
							
					<!--Desk 6 -->
						<svg x=1160 y=230>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0.5)" ref="desk6" data-desk="6" />
							<!-- <text x=380 y=610 font-size="50px">Desk 6</text> -->
							<foreignObject x=215 y=565 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(6)">
								<Avatar v-for="img in getAvatars(6).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(6).total > 3" :label="'+' + getAvatars(6).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0); z-index:10;" @click='svgClick($refs.desk6)' />
						</svg>

					<!--Desk 7 -->
						<svg x=1035 y=285>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0.5)" ref="desk7" data-desk="7" />
							<!-- <text x=30 y=590 font-size="50px" fill="white">Desk 7</text> -->
							<foreignObject x=215 y=550 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(7)">
								<Avatar v-for="img in getAvatars(7).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(7).total > 3" :label="'+' + getAvatars(7).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0); z-index:10;" @click='svgClick($refs.desk7)' />
						</svg>
					<!--Desk 8 -->
						<svg x=1020 y=550 >
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" ref="desk8" data-desk="8" style="fill:rgb(51, 156, 79, 0.5)" />
							<!-- <text x=30 y=640 font-size="50px" fill="white">Desk 8</text> -->
							<foreignObject x=200 y=610 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(8)">
								<Avatar v-for="img in getAvatars(8).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(8).total > 3" :label="'+' + getAvatars(8).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="fill:rgb(51, 156, 79, 0)" @click='svgClick($refs.desk8)' />
						</svg>
					<!--Desk 9 -->
						<svg x=1130 y=640>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0.5)" ref="desk9" data-desk="9"/>
							<!-- <text x=380 y=610 font-size="50px">Desk 9</text> -->
							<foreignObject x=215 y=565 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(9)">
								<Avatar v-for="img in getAvatars(9).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(9).total > 3" :label="'+' + getAvatars(9).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0); z-index:0;" @click='svgClick($refs.desk9)' />
						</svg>

					<!--Desk 10 -->
						<svg x=1000 y=690>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0.5)" ref="desk10" data-desk="10" />
							<!-- <text x=30 y=590 font-size="50px" fill="white">Desk 10</text> -->
							<foreignObject x=220 y=550 width="100" height="63">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(10)">
								<Avatar v-for="img in getAvatars(10).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(10).total > 3" :label="'+' + getAvatars(10).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0); z-index:10;" @click='svgClick($refs.desk10)' />
						</svg>
						<!--Desk 11 -->
						<rect x=1380 y=105 width="90" height="72" style="fill:rgb(51, 156, 79, 0.5)" ref="desk11" data-desk="11"/>
							<!-- <text id="dropShadowBlack" x=1633 y=380 fill="white" font-size="50px">1</text> -->
							<foreignObject x=1380 y=115 width="160" height="72">	
							<AvatarGroup class="p-mb-3" v-if="getAvatars(11)">
								<Avatar v-for="img in getAvatars(11).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(11).total > 3" :label="'+' + getAvatars(11).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1380 y=105 width="90" height="72" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk11)'/>
						<!--Desk 12 -->
						<rect x=1265 y=195 width="72" height="105" style="fill:rgb(51, 156, 79, 0.5)" ref="desk12" data-desk="12"/>
							<!-- <text id="dropShadowBlack" x=1633 y=380 fill="white" font-size="50px">1</text> -->
							<foreignObject x=1265 y=205 width="160" height="72">	
							<AvatarGroup class="p-mb-3" v-if="getAvatars(12)">
								<Avatar v-for="img in getAvatars(12).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(12).total > 3" :label="'+' + getAvatars(12).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1265 y=195 width="72" height="105" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk12)'/>
					</svg>
				</div>
				</div>
				<div class="p-grid p-justify-even" >
					<div class="p-col-2 p-lg-1 p-text-center p-mt-2">

					</div>
					<div class="p-col-4 p-lg-2">
					</div>
					<div class="p-col-3 p-lg-3 p-text-center p-mt-2">
						<label>Presets</label>
					</div>
				</div>
				<div class="p-grid p-justify-even" >
					<div class="p-col-2 p-lg-1 p-text-center p-mt-2">
					<label>Start:</label>
					</div>
					<div class="p-col-4 p-lg-2">
					<input
						type="time"
						class="form-control input-group p-col"
						v-model="startTime"
						@change="checkTime()"
						ref="startTime"
						/>
					</div>
					<div class="p-col-3 p-lg-3 p-text-center p-mt-2">
						<Button label="09:00 - 17:00" @click="setPreset(1)"/>
					</div>
				</div>
				<div class="p-grid p-justify-even" >
					<div class="p-col-2 p-lg-1 p-text-center p-mt-2">
					<label>End:</label>
					</div>
					<div class="p-col-4 p-lg-2">
						<input
							type="time"
							class="form-control input-group p-col"
							v-model="endTime"
							@change="checkTime()"
							ref="endTime"
							/>
					</div>
					<div class="p-col-3 p-lg-3 p-text-center p-mt-2">
						<Button label="09:00 - 13:00" @click="setPreset(2)"/>
					</div>
				</div>
				<div v-if="isAdmin" class="p-grid p-justify-even" >
					<div class="p-col-12 p-lg-12 p-text-center p-field" style="margin-top:auto; margin-bottom:auto;">
					<label>Guest Booking?</label><input type="checkbox" v-model="isGuest" @click="isGuest = !isGuest; checkHoursLeft();"/>
					</div>
				</div>
				<div v-if="isAdmin && isGuest" class="p-grid p-justify-even" >
					<div  class="p-col-10 p-lg-10 p-text-center p-text-center p-field" style="margin-top:auto; margin-bottom:auto;"> 
						<label>Description: </label>
						<InputText type="text" v-model="guestDescription" />
					</div>
				</div>
				<div class="p-col-12 p-lg-12"> 
					<ConfirmPopup style="z-index:1500;">
					</ConfirmPopup>
					<Button ref="popup" :disabled="checkButton()" @click="submitBooking($event)" label="Book" class="p-col-12"></Button>
				</div>
			</component>
		</div>
		<div v-if="selectedDeskDetails" class="p-col-12">
			<Panel :header="'Desk ' + selectedDeskDetails + ' Details'">
				<div class="p-field" v-for="detail in selectedDeskDetailsContent" :key="detail">
					{{detail}}
				</div>
			</Panel>
		</div>
		<div class="p-col-12">
			<Panel :header='"Parking Card Booking (" + availableParking + " available) (" + dateBooking + ")"'>
				<div class="p-field">
					<Button ref="popup" :disabled="disableParkingCard" @click="submitParkingBooking(false)" :label="parkingButtonString" class="p-col-12"></Button>
				</div>
				<div class="p-field" v-for="parking in cardParkings" :key="parking">
					{{"Card " + parking.card + ": " + parking.user.first_name + " " + parking.user.last_name}}
				</div>
			</Panel>
		</div>
		<div class="p-col-12">
			<Panel :header='"Personal Parking Card Booking (" + dateBooking + ")"'>
				<div class="p-field">
					<Button ref="popup" :disabled="disablePersonalParking" @click="submitParkingBooking(true)" :label="personalParkingButtonString" class="p-col-12"></Button>
				</div>
				<div class="p-field" v-for="parking in personalParkings" :key="parking">
					{{parking.user.first_name + " " + parking.user.last_name}}
				</div>
				<div :class="{ hide2: !showNextDayParkingMsg}">
					Bookings for tomorrow will open at 17:00
				</div>
			</Panel>
		</div>
		<div class="p-col-12">
			<InlineMessage v-if="errorMessage">{{errorMessage}}</InlineMessage>
		</div>
		<Dialog header="Booking Error" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true" :showCloseIcon="false">
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span>Unfortunately an error has occured while booking, please try again.</span>
			</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="closeConfirmation" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<Dialog header="Booking Error" v-model:visible="displayErrorBooking" :style="{width: '350px'}" :modal="true" :showCloseIcon="false">
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<div>Unfortunately an error has occured while saving the following bookings. Please try again.</div>
			</div>
			<br>
			<div>{{errorEvent.name}}</div>
			<div v-for="err in errorEvent.dates" :key="err" :value="err">{{formatDate(err.start)}} to {{formatDate(err.end)}}</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="closeBookingError" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<Dialog header="Booking Success" v-model:visible="displayBookingSuccess" :style="{width: '350px'}" :modal="true" :showCloseIcon="false">
			<div class="confirmation-content">
				<i class="pi pi-check-circle p-mr-3" style="font-size: 2rem" />
				<div>Booking saved successfully!</div>
			</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="closeBookingSuccess" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<Dialog header="Parking Booking Success" v-model:visible="displayParkingSuccess" :style="{width: '350px'}" :modal="true" :showCloseIcon="false">
			<div class="confirmation-content">
				<i class="pi pi-check-circle p-mr-3" style="font-size: 2rem" />
				<div>Parking booked successfully!</div>
			</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="closeParkingSuccess" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<Dialog header="Parking Booking Failed" v-model:visible="displayParkingError" :style="{width: '350px'}" :modal="true" :showCloseIcon="false">
			<div class="confirmation-content">
				<i class="pi pi-check-circle p-mr-3" style="font-size: 2rem" />
				<div>Unable to book parking, please try again</div>
			</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="closeParkingError" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<Dialog header="Parking Booking Cancelled" v-model:visible="displayParkingDeleted" :style="{width: '350px'}" :modal="true" :showCloseIcon="false">
			<div class="confirmation-content">
				<i class="pi pi-check-circle p-mr-3" style="font-size: 2rem" />
				<div>Parking Booking Cancelled successfully!</div>
			</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="closeParkingDeleted" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<Dialog header="Office Map" :visible="false" :modal="true" :showCloseIcon="true">
				<div class="p-grid p-justify-even">
					<div class="img-overlay-wrap">

					<img src="assets/layout/images/DOGOffice.webp"/>
					<svg viewBox="0 0 2969 1670">
						<!--Desk 1 -->
						<rect x=1633 y=380 width="160" height="72" style="fill:rgb(51, 156, 79, 0.5); z-index:5;" ref="desk1" data-desk="1"/>
							<!-- <text id="dropShadowBlack" x=1633 y=380 fill="white" font-size="50px">1</text> -->
							<foreignObject x=1633 y=390 width="160" height="72">	
							<AvatarGroup class="p-mb-3" v-if="getAvatars(1)">
								<Avatar v-for="img in getAvatars(1).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(1).total > 3" :label="'+' + getAvatars(1).plus" shape="circle" size="" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1633 y=380 width="160" height="72" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk1)'/>
						<!--Desk 2 -->
						<rect x=1793 y=380 width="160" height="72" style="fill:rgb(51, 156, 79, 0.5)" ref="desk2" data-desk="2"/>
							<!-- <text id="dropShadowBlack" x=1800 y=380 fill="white" font-size="50px">Desk 2</text> -->
							<foreignObject x=1795 y=390 width="160" height="72">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(2)">
								<Avatar v-for="img in getAvatars(2).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(2).total > 3" :label="'+' + getAvatars(2).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1793 y=380 width="160" height="72" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk2)'/>
						<!--Desk 3 -->
						<rect x=1633 y=476 width="160" height="75" style="fill:rgb(51, 156, 79, 0.5)" ref="desk3" data-desk="3"/>
							<!-- <text x=1633 y=590 font-size="50px">Desk 3</text> -->
							<foreignObject x=1633 y=490 width="160" height="75">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(3)">
								<Avatar v-for="img in getAvatars(3).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(3).total > 3" :label="'+' + getAvatars(3).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1633 y=476 width="160" height="75" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk3)'/>
						<!--Desk 4 -->
						<rect x=1793 y=476 width="160" height="75" style="fill:rgb(51, 156, 79, 0.5)" ref="desk4" data-desk="4"/>
							<!-- <text x=1800 y=590 font-size="50px">Desk 4</text> -->
							<foreignObject x=1795 y=490 width="160" height="75">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(4)">
								<Avatar v-for="img in getAvatars(4).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(4).total > 3" :label="'+' + getAvatars(4).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<rect x=1793 y=476 width="160" height="75" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk4)'/>
					<!--Desk 5 -->
						<svg x=1055 y=165>
							<path d="M 186.219 651.7665 L 303.537 651.7665 L 364.9892 547.4839 L 294.226 506.5157 C 297.9504 510.2401 279.3285 577.2789 186.219 573.5545 L 186.219 573.5545 Z" style="fill:rgb(51, 156, 79, 0.5)" ref="desk5" data-desk="5" @click='svgClick($refs.desk5)' />
							<!-- <text id="dropShadowBlack" x=10 y=620 font-size="50px" fill="white">Desk 5</text> -->
							<foreignObject x=195 y=590 width="85" height="48">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(5)">
								<Avatar v-for="img in getAvatars(5).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(5).total > 3" :label="'+' + getAvatars(5).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 186.219 651.7665 L 303.537 651.7665 L 364.9892 547.4839 L 294.226 506.5157 C 297.9504 510.2401 279.3285 577.2789 186.219 573.5545 L 186.219 573.5545 Z" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk5)' />
						</svg>
							
					<!--Desk 6 -->
						<svg x=1160 y=230>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0.5)" ref="desk6" data-desk="6" />
							<!-- <text x=380 y=610 font-size="50px">Desk 6</text> -->
							<foreignObject x=215 y=565 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(6)">
								<Avatar v-for="img in getAvatars(6).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(6).total > 3" :label="'+' + getAvatars(6).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0); z-index:10;" @click='svgClick($refs.desk6)' />
						</svg>

					<!--Desk 7 -->
						<svg x=1035 y=285>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0.5)" ref="desk7" data-desk="7" />
							<!-- <text x=30 y=590 font-size="50px" fill="white">Desk 7</text> -->
							<foreignObject x=215 y=550 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(7)">
								<Avatar v-for="img in getAvatars(7).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(7).total > 3" :label="'+' + getAvatars(7).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box;transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0); z-index:10;" @click='svgClick($refs.desk7)' />
						</svg>
					<!--Desk 8 -->
						<svg x=1020 y=550 >
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" ref="desk8" data-desk="8" style="fill:rgb(51, 156, 79, 0.5)" />
							<!-- <text x=30 y=640 font-size="50px" fill="white">Desk 8</text> -->
							<foreignObject x=200 y=610 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(8)">
								<Avatar v-for="img in getAvatars(8).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(8).total > 3" :label="'+' + getAvatars(8).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="fill:rgb(51, 156, 79, 0)" @click='svgClick($refs.desk8)' />
						</svg>
					<!--Desk 9 -->
						<svg x=1130 y=640>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0.5)" ref="desk9" data-desk="9"/>
							<!-- <text x=380 y=610 font-size="50px">Desk 9</text> -->
							<foreignObject x=215 y=565 width="85" height="60">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(9)">
								<Avatar v-for="img in getAvatars(9).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(9).total > 3" :label="'+' + getAvatars(9).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(120deg); fill:rgb(51, 156, 79, 0); z-index:0;" @click='svgClick($refs.desk9)' />
						</svg>

					<!--Desk 10 -->
						<svg x=1000 y=690>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0.5)" ref="desk10" data-desk="10" />
							<!-- <text x=30 y=590 font-size="50px" fill="white">Desk 10</text> -->
							<foreignObject x=220 y=550 width="100" height="63">
							<AvatarGroup class="p-mb-3" v-if="getAvatars(10)">
								<Avatar v-for="img in getAvatars(10).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(10).total > 3" :label="'+' + getAvatars(10).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
							<path d="M 193.05 675.675 L 314.6715 675.675 L 378.378 567.567 L 305.019 525.096 C 308.88 528.957 289.575 598.455 193.05 594.594 L 193.05 594.594 Z" style="transform-box: fill-box; transform-origin: center;
							transform: rotate(240deg); fill:rgb(51, 156, 79, 0); z-index:10;" @click='svgClick($refs.desk10)' />
						</svg>
						<!--Desk 11 -->
						<rect x=1380 y=105 width="90" height="72" style="fill:rgb(51, 156, 79, 0.5)" ref="desk11" data-desk="11"/>
							<!-- <text id="dropShadowBlack" x=1633 y=380 fill="white" font-size="50px">1</text> -->
							<foreignObject x=1380 y=115 width="160" height="72">	
							<AvatarGroup class="p-mb-3" v-if="getAvatars(11)">
								<Avatar v-for="img in getAvatars(11).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(11).total > 3" :label="'+' + getAvatars(11).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1380 y=105 width="90" height="72" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk11)'/>
						<!--Desk 12 -->
						<rect x=1265 y=195 width="72" height="105" style="fill:rgb(51, 156, 79, 0.5)" ref="desk12" data-desk="12"/>
							<!-- <text id="dropShadowBlack" x=1633 y=380 fill="white" font-size="50px">1</text> -->
							<foreignObject x=1265 y=205 width="160" height="72">	
							<AvatarGroup class="p-mb-3" v-if="getAvatars(12)">
								<Avatar v-for="img in getAvatars(12).images" :key="img" :value="img" :image="img" size="xlarge" shape="circle" class="clip-circle"></Avatar>
								<Avatar v-if="getAvatars(12).total > 3" :label="'+' + getAvatars(12).plus" shape="circle" size="xlarge" :style="{'background-color':'#9c27b0', 'color': '#ffffff', 'z-index': '9'}"></Avatar>
							</AvatarGroup>
							</foreignObject>
						<rect x=1265 y=195 width="72" height="105" style="fill:rgb(255,0,0,0); z-index:10;" @click='svgClick($refs.desk12)'/>
					</svg>
				</div>
				</div>
			<template #footer>
				<Button label="Close" icon="pi pi-check" @click="displayFullScreenMap=false;" class="p-button-text" autofocus />
			</template>
		</Dialog>
	</div>
</div>
</div>
</template>

<script>
import { inject } from "vue";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { server } from "@/helper";
import axios from "axios";
import { _ } from "vue-underscore"
import dayjs from 'dayjs';

export default {
	data() {
		return {
			options: {
				plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
				header: {
					left: 'prev,next',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay'
				},
				locale: 'gb',
				firstDay: 1,
				eventLimit: true,
				editable: false,
				handleWindowResize: true,
				dateClick: (info) => {
					var today = dayjs().hour(0).minute(0).second(0).millisecond(0);
					if(dayjs(info.dateStr) < today) return;
					var that = this;
					var existsDay = _.some(this.selectedDays, function(d){
						return info.dateStr == d;
					});
					if(existsDay){
						this.selectedDays = _.reject(this.selectedDays, function(d){
							return info.dateStr == d;
						});
						info.dayEl.style.backgroundColor = '';
						if(this.selectedDays.length == 0){
							_.each(this.deskFill, function(d){ d.fill = that.rgbDefault;});
							for(var a = 1; a <= this.numDesks; a++){
								this.$refs['desk'+a].style.fill = this.rgbDefault;
							}
							this.avatarsDesks = [];
							this.lastDaySelected = null;
						}
						this.checkHoursLeft();
						this.checkTime();
					}
					else{
						var selectedDayValue = info.dateStr;
						var selectedDate = dayjs(selectedDayValue);
						var advancedDaysBooking = _.filter(this.settings, function(s){
							return s.name == "advancedDaysBooking";
						});
						advancedDaysBooking = parseInt(_.max(advancedDaysBooking, function(b){
							return b.role;
						}).value);
						if(selectedDate > today.add(advancedDaysBooking, 'day')){
							return;
						}
						var multiDayBookingAllowed = _.some(this.settings, function(s){
							return s.name === "allowMultiDayBooking" && s.value.toLowerCase() === 'true';
						});
						if(this.selectedDays.length > 0 && !multiDayBookingAllowed) {
							return;
						}
						info.dayEl.style.backgroundColor = 'rgb(114, 192, 237, 0.5)';
						
						this.lastDaySelected = info.dateStr;
						this.selectedDays.push(selectedDayValue);
						this.selectedDaysEl.push(info.dayEl);
					
						this.avatarsDesks = [];
						var selectedEvents = _.filter(this.events, function(e){
							return (dayjs(e.start).get('date') == selectedDate.get('date')) && (dayjs(e.start).get('month') == selectedDate.get('month')) 
							&& (dayjs(e.start).get('year') == selectedDate.get('year'));
						});
						if(selectedEvents){
							//var that = this;
							for(var i = 1; i <= this.numDesks; i++){
								this.$refs['desk'+i].style.fill = this.rgbDefault;
							}
							_.each(this.deskFill, function(d){ d.fill = that.rgbDefault;});
							var totalHours = 0;
							_.each(selectedEvents, function(e){
								var fillback = '';
								totalHours += dayjs(e.end).diff(dayjs(e.start), 'hour', true);
								if(totalHours < 7.5){
									fillback = 'rgb(255, 255, 0, 0.5)';
								}
								else {
									fillback = 'rgb(255, 0, 0, 0.5)';
								}
								that.$refs['desk'+e.desk].style.fill = fillback;
								_.each(that.deskFill, function(d){ if(parseInt(e.desk) == d.desk) d.fill = fillback; })
								var avatars = _.find(that.avatarsDesks, function(a){
									return a.desk == e.desk;
								})
								if(avatars){
									avatars.images.push(e.user.imageUrl);
									avatars.total = avatars.total+1;
								}
								else{
									avatars = { desk: e.desk, images: [e.user.imageUrl], total: 1 };
									that.avatarsDesks.push(avatars);
								}
							})
							if(this.selectedDesks.length > 0){
								_.each(this.selectedDesks, function(d){
									that.$refs['desk'+d].style.fill = 'rgb(114, 192, 237, 0.5)';
								})
								
							}
						}
						if(this.reservedDesks && !_.some(this.settings, function(s){
							return _.some(that.reservedDesks, function(r){
								return s.name == "reservedDesk" && s.value == r;
							}) 
						}) && selectedDate.hour(16).minute(59).second(59).diff(dayjs(), 'hour', true) > 24) {
							_.each(this.reservedDesks, function(r){
								if(!_.some(selectedEvents, function(e){
									return e.desk == r;
								})) {
									that.$refs['desk'+r].style.fill = 'rgb(192, 192, 192, 0.5)';
								}
							})
						}
						if(!this.checkHoursLeft()) return;
						if(this.selectedDesks.length > 0)
							this.selectionDone = true;

						this.errorMessage = "";
						if(this.selectionDone && this.startTime && this.endTime)
							this.disableSubmit = false;

					}
				},
				datesRender: (info) => {
					this.viewDate = dayjs(info.view.currentStart);
					//this.refreshEvents();
					_.each(this.selectedDays, function(e){
						var el = info.view.el.querySelectorAll("[data-date='" + e + "']");
						if(el && el.length > 0)
							el[0].style.backgroundColor = 'rgb(114, 192, 237, 0.5)';
					});
				},
				eventClick: (info) => {
					if(this.isAdmin || info.event.extendedProps.user.email == this.$gAuth.instance.currentUser.get().getBasicProfile().getEmail()){
						this.$confirm.require({
							target: info.el,
							message: 'Would you like to delete this booking?',
							icon: 'pi pi-question-circle',
							accept: () => {
								this.deleteEvent(info.event.extendedProps.eventId, false);
							}
						});
					}
				},
				eventMouseEnter: (info) => {
					if(this.isAdmin || info.event.extendedProps.user.email == this.$gAuth.instance.currentUser.get().getBasicProfile().getEmail()){
						info.el.style.cursor = 'pointer';
					}
				},
				eventRender: (info) => {
					if(info.view.type == "dayGridMonth")
						info.el.style.height = "30px";
				}
			},
			events: null,
			productService: null,
			eventService: null,
			selectionDone: false,
			errorMessage: '',
			disableSubmit: true,
			isLoaded: false,
			intervalCheck: null,
			intervalRefresh: null,
			maxHours: 0,
			hoursLeft: 9999,
			rgbDefault: 'rgb(51, 156, 79, 0.5)',
			deskFill: [],
			avatarsDesks: [],
			displayConfirmation: false,
			viewDate: dayjs(),
			errorEvent: {},
			displayErrorBooking: false,
			displayBookingSuccess: false,
			displayParkingSuccess: false,
			displayParkingError: false,
			limitReached: false,
			profile: null,
			numDesks: 0,
			isAdmin: false,
			selectedDays: [],
			selectedDaysEl: [],
			lastDaySelected: null,
			isGuest: false,
			selectedDesks: [],
			selectedDeskDetails: null,
			selectedDeskDetailsContent: [],
			parkingButtonString: 'Book',
			personalParkingButtonString: 'Book',
			availableParking: process.env.VUE_APP_PARKING_SPOTS,
			cardParkings: [],
			personalParkings: [],
			disableParkingCard: true,
			disablePersonalParking: true,
			userHasParkingBooked: false,
			displayParkingDeleted: false,
			showNextDayParkingMsg: false,
			dateBooking: dayjs().format('DD/MM/YYYY'),
			shiftPressed: false,
			displayFullScreenMap: false,
			fullscreenIcon: "pi-window-maximize",
			fullscreenIconStyle: "",
			roles: null,
			settings: null,
			reservedDesks: null
		}
	},
	setup() {
		const Vue3GoogleOauth = inject("Vue3GoogleOauth");
		const monthsHours = inject("monthsHours");
		const updateMonths = inject("updateMonthsHours");
		return {
			Vue3GoogleOauth,
			monthsHours,
			updateMonths
		};
		
	},
	created() {
		if(this.intervalCheck) clearInterval(this.intervalCheck);
		if(this.intervalRefresh) clearInterval(this.intervalRefresh);
	},
	mounted() {
		var that = this;
		this.intervalCheck = setInterval(function(){
			if(that.Vue3GoogleOauth.isInit){
				that.checkLogin();
				clearInterval(that.intervalCheck);
			}
		}, 500);
	},
	methods: {
		checkLogin() {
			if(!this.Vue3GoogleOauth.isAuthorized){
				this.$router.push({ name: 'login' });
			}
			else{
				this.getEvents();
				this.isLoaded = true;
				this.viewDate = dayjs();
				var that = this;
					this.intervalRefresh = setInterval(function(){
					if(that.Vue3GoogleOauth.isInit){
						that.refreshEvents();
					}
				}, 10000);
			}
		},
		checkHoursLeft() {
			var that = this;
			var profile = this.$gAuth.instance.currentUser.get().getBasicProfile();
			var userEvents = _.filter(this.events, function(e){
				return (e.user.email == profile.getEmail()); // && (dayjs(e.start).month() == selectedDate.month());
			});
			
			var months = [];
			_.each(this.selectedDays, function(day){
				var date = dayjs(day);
				date = date.set('date', 1);
				if(!_.some(months, function(m) { return (m.month.month() == date.month() && m.month.year() == date.year()); }))
					months.push({ month: date, hoursLeft: that.maxHours });
			});
			if(userEvents){
				_.each(months, function(m){
					var events = _.filter(userEvents, function(e){
						return (dayjs(e.start).month() == m.month.month() && dayjs(e.start).year() == m.month.year());
					})
					_.each(events, function(ev){
						var hours = dayjs(ev.end).diff(dayjs(ev.start), 'h', true);
						if(!ev.user.isGuest && !ev.sameDay)
							m.hoursLeft = m.hoursLeft - hours;
					})
				})
			}
			if(this.startTime && this.endTime){
				_.each(months, function(m){
					var days = _.filter(that.selectedDays, function(d){
						return (m.month.month() == dayjs(d).month() && m.month.year() == dayjs(d).year());
					})
					_.each(days, function(day){
						var d = dayjs(day);
						if(!(d.date() == dayjs().date() && d.month() == dayjs().month() && d.year() == dayjs().year() && dayjs().hour() >= 7 && dayjs().minute() >= 0))
						{
							var start = day + 'T' + that.startTime + ':00' + that.getTimeZone();
							var end = day + 'T' + that.endTime + ':00' + that.getTimeZone();
							if(!that.isGuest){
								_.each(that.selectedDesks, function(){
									m.hoursLeft -= dayjs(end).diff(dayjs(start), 'h', true);
								})
								
							}
						}
					});
				})
			}
			this.limitReached = false;
			this.errorMessage = '';
			_.each(months, function(m){
				if(m.hoursLeft < 0){
					that.limitReached = true;
					if(that.errorMessage != null && that.errorMessage != '')
						that.errorMessage = that.errorMessage + ', ' + m.month.format("MMMM YYYY");
					else
						that.errorMessage = that.errorMessage + 'You have reached your limit for ' + m.month.format("MMMM YYYY");
				}
			})
			this.monthHours = [];
			_.each(months, function(m){
				var val = {};
				val.month = dayjs(m.month).format('MMMM');
				val.hoursLeft = m.hoursLeft;
				that.monthHours.push(val);
			});
			this.$store.commit('addMonth', this.monthHours);

			if(this.limitReached){
				return false;
			}
			return true;
		},
        getEvents() {
			var request = { email: this.$gAuth.instance.currentUser.get().getBasicProfile().getEmail() };
            axios
                .post(`${server.baseURL}/calendar/events`, request)
                .then(data => { 
					var that = this;
					this.roles = data.data.roles;
					this.settings = data.data.settings;
					this.reservedDesks = data.data.reservedDesks;
					this.events = data.data.events;
					this.maxHours = data.data.maxHours;
					this.numDesks = data.data.numDesks;
					var parkingsTaken = _.countBy(data.data.parkings, function(p){
						return !p.personal ? 'cards' : 'personal';
					});
					if(parkingsTaken.cards)
						this.availableParking = parseInt(process.env.VUE_APP_PARKING_SPOTS - parkingsTaken.cards);
					else{
						this.availableParking = parseInt(process.env.VUE_APP_PARKING_SPOTS);
					}
					
					this.cardParkings = [];
					this.personalParkings = [];
					_.each(data.data.parkings, function(p){
						if(!p.personal){
							that.cardParkings.push(p);
						}
						else{
							that.personalParkings.push(p);
						}
					});
					if(data.data.isAdmin)
						this.isAdmin = true;
					this.setDesks();
					this.getDeskDetails();
					this.checkParkingButtons();
				});
        },
		getDeskDetails(){
			axios
                .get(`${server.baseURL}/desk/details`)
                .then(data => { 
					this.deskDetails = data.data.desks;
				});
		},
		setDesks(){
			for(var i = 1; i <= this.numDesks; i++){
				this.deskFill.push( { desk: i, fill: this.rgbDefault});
			}
		},
		refreshEvents() {
			var request = { currentDate: this.viewDate };
            axios
                .post(`${server.baseURL}/calendar/searchEvents`, request)
                .then(data => { 
					this.events = data.data.events; 
					var parkingsTaken = _.countBy(data.data.parkings, function(p){
						return !p.personal ? 'cards' : 'personal';
					});
					if(parkingsTaken.cards)
						this.availableParking = parseInt(process.env.VUE_APP_PARKING_SPOTS - parkingsTaken.cards);
					else{
						this.availableParking = parseInt(process.env.VUE_APP_PARKING_SPOTS);
					}
					this.cardParkings = [];
					this.personalParkings = [];
					var that = this;
					_.each(data.data.parkings, function(p){
						if(!p.personal){
							that.cardParkings.push(p);
						}
						else{
							that.personalParkings.push(p);
						}
					});
					this.checkParkingButtons();
				});
		},
		svgClick(element){
			var that = this;
			var selectedDetails = this.selectedDeskDetails;
			if(this.selectedDays.length > 0){
				for(var i = 1; i <= this.numDesks; i++){
					if(!_.some(this.selectedDesks, function(d){
						return d == i.toString();
						}) &&
						this.reservedDesks && _.contains(this.reservedDesks, i) && 
							!_.some(this.settings, function(s){
								return _.some(that.reservedDesks, function(r){
									return s.name == "reservedDesk" && s.value == r;
								}) 
							})
							&& dayjs(that.lastDaySelected).hour(16).minute(59).second(59).diff(dayjs(), 'hour', true) > 24)
					{
						this.$refs['desk'+i].style.fill = _.find(this.deskFill, function(f) { return f.desk == i}).fill;
						this.selectedDeskDetails = null;
					}
				}

				if(_.some(this.selectedDesks, function(d){
					return d == element.dataset.desk;
				})){
					this.selectedDesks = _.reject(this.selectedDesks, function(d){
						return d == element.dataset.desk;
					})
					element.style.fill = _.find(this.deskFill, function(f) { return f.desk == element.dataset.desk}).fill;
					//_.find(this.deskFill, function(f) { return f.desk == element.dataset.desk}).fill = this.rgbDefault;
				}
				else{
					if(this.selectedDesks.length == 0 || this.shiftPressed){
						if(this.reservedDesks && _.contains(this.reservedDesks, element.dataset.desk) && 
							!_.some(this.settings, function(s){
								return _.some(that.reservedDesks, function(r){
									return s.name == "reservedDesk" && s.value == r;
								}) 
							})
							&& dayjs(that.lastDaySelected).hour(16).minute(59).second(59).diff(dayjs(), 'hour', true) > 24) {
							return;
						}
						this.selectedDesks.push(element.dataset.desk);
						_.each(this.selectedDesks, function(d){
							that.$refs['desk'+d].style.fill = "rgb(114, 192, 237, 0.5)";
						});
						this.selectedDeskDetails = element.dataset.desk;
						var selectedDayValue = this.selectedDays[0];
						var selectedDate = dayjs(selectedDayValue);
						var eventsInDate = _.filter(this.events, function(e){
						return (dayjs(e.start).get('date') == selectedDate.get('date')) && (dayjs(e.start).get('month') == selectedDate.get('month')) 
							&& (dayjs(e.start).get('year') == selectedDate.get('year') && _.some(that.selectedDesks, function(d){
								return parseInt(d) == parseInt(e.desk);
							}))
						});
						this.selectedDeskDetailsContent = [];
						_.each(eventsInDate, function(e){
							var details = e.user.first_name + " " + e.user.last_name + " : " + dayjs(e.start).format('HH:mm') + ' - ' + dayjs(e.end).format('HH:mm');
							that.selectedDeskDetailsContent.push(details); 
						})
					}
					else{
						this.selectedDeskDetails = selectedDetails;
						return;
					}
						
					
					//element.style.fill = "rgb(114, 192, 237, 0.5)";
					//_.find(this.deskFill, function(f) { return f.desk == element.dataset.desk}).fill = "rgb(114, 192, 237, 0.5)";
				}
				
				this.checkHoursLeft();
				if(this.selectedDays.length > 0){
					this.selectionDone = true;
				}
				if(this.selectionDone && this.startTime && this.endTime){
					this.checkTime();
				}
				
			}	
		},
		submitBooking(event){
			console.log("submit");
			this.$confirm.require({
					target: event.currentTarget,
					message: 'Are you sure you want to proceed?',
					icon: 'pi pi-exclamation-triangle',
					position: 'top',
					accept: () => {
						if(this.selectedDays.length > 0 && this.selectedDesks.length > 0 && this.startTime && this.endTime){
							var profile = this.$gAuth.instance.currentUser.get().getBasicProfile();
							var events = [];
							for(var i = 0; i < this.selectedDesks.length; i++){
								var eventName = 'Desk ' + this.selectedDesks[i] + ' ';
								if(!this.isGuest) eventName = eventName + profile.getName();
								else eventName = eventName + this.guestDescription;
								var event = { 	name: eventName, 
												dates: [],
												user: { first_name: profile.getGivenName(), last_name: profile.getFamilyName(), email: profile.getEmail(), imageUrl: profile.getImageUrl()},
												desk: this.selectedDesks[i],
												isGuest: this.isGuest
								};
								var that = this;
								_.each(this.selectedDays, function(d){
									var date = { start: d + 'T' + that.startTime + ':00' + that.getTimeZone(), 
												end: d + 'T' + that.endTime  + ':00' + that.getTimeZone()};
									event.dates.push(date);
								})
								events.push(event);
							}
							try{
								axios.post(`${server.baseURL}/calendar/create`, events)
									.then(data =>
									{
										if(data.data.result){
											this.refreshEvents();
											this.disableSubmit = true;
											this.displayFullScreenMap = false;
											this.openBookingSuccess();
										}
										else{
											this.errorEvent = data.data.evFailed;
											this.openBookingError();
										}
									})
									.catch(() => 
									{
										this.openConfirmation();
									});
							}
							catch(e){
								this.openConfirmation();
							}
						}
					}
				});
		},
		getTimeZone(){
			var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
			return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
		},
		formatDate(date){
			return dayjs(date).format('DD/MM/YYYY HH:mm');
		},
		checkTime(){
			if(this.selectedDays.length > 0 && this.selectedDesks.length > 0 && this.startTime && this.endTime){
				this.selectedDays = _.sortBy(this.selectedDays, function(d){
					return d;
				})
				var selectedDayValue = this.selectedDays[0];
				var selectedDate = dayjs(selectedDayValue);
				var start = dayjs(selectedDayValue + 'T' + this.startTime + ':00');
				var end = dayjs(selectedDayValue + 'T' + this.endTime + ':00');
				var that = this;
				var eventsInDate = _.filter(this.events, function(e){
					return (dayjs(e.start).get('date') == selectedDate.get('date')) && (dayjs(e.start).get('month') == selectedDate.get('month')) 
						&& (dayjs(e.start).get('year') == selectedDate.get('year') && _.some(that.selectedDesks, function(d){
							return parseInt(e.desk) == d
						}))
				});
				//var dateDiff = end.diff(start, 'h', true);
				//var remaining = this.hoursLeft - dateDiff;
				var today = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate())
				var dayjsToday = dayjs(today);

				if(dayjs() > start){
					this.errorMessage = "Date selected must be in the future";
					this.disableSubmit = true;
				}
				else if(end <= start){
					this.errorMessage = "End date must be larger than start date";
					this.disableSubmit = true;
				}
				else if((selectedDate.year() == dayjsToday.year() && selectedDate.month() == dayjsToday.month() && selectedDate.date() == dayjsToday.date()) &&
						dayjs().diff(start) > 0){
					this.errorMessage = "Date/Time selected must be in the future";
					this.disableSubmit = true;
				}
				else if(dayjs(end).diff(dayjs(start), 'h', true) > 8){
					this.errorMessage = "Cannot book more than 8 hours per day!";
					this.disableSubmit = true;
				}
				else if(!this.checkHoursLeft()){
					//this.errorMessage = "This booking cannot be done as it will exceed your monthly limit. You have " + this.hoursLeft + " hours remaining.";
					this.disableSubmit = true;
				}
				else{
					if(eventsInDate){
						var conflictingTime = _.some(eventsInDate, function(e){
							return (dayjs(e.start) <= start && dayjs(e.end) >= start) || (dayjs(e.start) <= end && dayjs(e.end) >= end);
						})
						if(!conflictingTime){
							this.disableSubmit = false;
							this.errorMessage = "";
						}
						else{
							this.disableSubmit = true;
							this.errorMessage = "Time is already booked, please choose a different date/time";
						}
					}
					else
						this.disableSubmit = false;
				}
			}
			else{
				this.disableSubmit = true;
			}
				
		},
		openConfirmation() {
			this.displayConfirmation = true;
		},
		closeConfirmation() {
			this.displayConfirmation = false;
		},
		openBookingError() {
			this.displayErrorBooking = true;
		},
		closeBookingError() {
			this.displayErrorBooking = false;
		},
		openBookingSuccess() {
			this.displayBookingSuccess = true;
		},
		closeBookingSuccess() {
			this.displayBookingSuccess = false;
		},
		openParkingSuccess() {
			this.displayParkingSuccess = true;
		},
		closeParkingSuccess() {
			this.displayParkingSuccess = false;
		},
		openParkingError() {
			this.displayParkingError = true;
		},
		closeParkingError() {
			this.displayParkingError = false;
		},
		openParkingDeleted(){
			this.displayParkingDeleted = true;
		},
		closeParkingDeleted(){
			this.displayParkingDeleted = false;
		},
		checkButton(){
			return (this.disableSubmit || this.limitReached);
		},
		checkParkingButtons(){
			var now = dayjs();
			var endDay = dayjs().hour(16).minute(59).second(59);
			//var startNextDay = dayjs().hour(0).minute(0).second(0).add(1, 'day');
			//var endNextDay = dayjs().hour(23).minute(59).second(59).add(1, 'day');
			var that = this;
			// var nextDayUserEventExists = _.some(this.events, function(ev){
			// 	return (dayjs(ev.start) >= startNextDay && dayjs(ev.start) <= endNextDay) && ev.user.email == that.$gAuth.instance.currentUser.get().getBasicProfile().getEmail();
			// })
			var hasCardBooking = _.some(this.cardParkings, function(p){
				return that.$gAuth.instance.currentUser.get().getBasicProfile().getEmail() == p.user.email;
			})
			var hasPersonalBooking = _.some(this.personalParkings, function(p){
				return that.$gAuth.instance.currentUser.get().getBasicProfile().getEmail() == p.user.email;
			})
			
			if(hasCardBooking){
				this.parkingButtonString = "Cancel booking";
				this.personalParkingButtonString = "You have already booked a parking spot using a Dorado Card";
				this.disableParkingCard = false;
				this.disablePersonalParking = true;
				this.userHasParkingBooked = true;
			}
			else if(hasPersonalBooking){
				this.parkingButtonString = "You have already booked a parking spot using a personal card";
				this.personalParkingButtonString = "Cancel Booking";
				this.disableParkingCard = true;
				this.disablePersonalParking = false;
				this.userHasParkingBooked = true;
			}
			// else if(!nextDayUserEventExists){
			// 	this.parkingButtonString = "You have no desk bookings for tomorrow";
			// 	this.personalParkingButtonString = "You have no desk bookings for tomorrow";
			// 	this.disableParkingCard = true;
			// 	this.disablePersonalParking = true;
			// }
			else if((now > endDay) && now.date() == endDay.date() && now.month() == endDay.month() && now.year() == endDay.year()){
				if(this.availableParking > 0){
					this.parkingButtonString = "Book";
					this.disableParkingCard = false;
				}
				else{
					this.parkingButtonString = "There is no parking cards available for tomorrow";
					this.disableParkingCard = true;
				}
				this.personalParkingButtonString = "Book";
				this.disablePersonalParking = false;
				this.showNextDayParkingMsg = false;
				this.dateBooking = dayjs().add(1, 'day').format('DD/MM/YYYY');
			}
			else if((now < endDay) && now.date() == endDay.date() && now.month() == endDay.month() && now.year() == endDay.year()){
				if(this.availableParking > 0){
					this.parkingButtonString = "Book";
					this.disableParkingCard = false;
				}
				else{
					this.parkingButtonString = "There is no parking cards available for today";
					this.disableParkingCard = true;
				}
				this.personalParkingButtonString = "Book";
				this.disablePersonalParking = false;
				this.showNextDayParkingMsg = true;
			}
			else{
				this.parkingButtonString = "Booking opens at 17:00";
				this.personalParkingButtonString = "Booking opens at 17:00";
				return true;
			}
		},
		submitParkingBooking(isPersonal){
			var profile = this.$gAuth.instance.currentUser.get().getBasicProfile();
			if(!this.userHasParkingBooked){
				var parking = {
					personal: isPersonal,
					user: { first_name: profile.getGivenName(), last_name: profile.getFamilyName(), email: profile.getEmail(), imageUrl: profile.getImageUrl()}
				}
				axios.post(`${server.baseURL}/calendar/bookParking`, parking)
					.then(data =>
					{
						if(data){
							this.openParkingSuccess();
						}
						else{
							this.openParkingError();
						}
						this.refreshEvents();
					})
			}
			else{
				var park = _.find(this.cardParkings, function(p){
					return p.user.email == profile.getEmail();
				});
				if(!park){
					park = _.find(this.personalParkings, function(p){
						return p.user.email == profile.getEmail();
					});
				}
				this.deleteEvent(park.eventId, true);
			}
		},
		getAvatars(desk){
			var avatars = _.find(this.avatarsDesks, function(a){
				return a.desk == desk;
			})
			if(!avatars)
				return avatars;
			else if(avatars.images.length < 3){
				return avatars;
			}
			else{
				var avs = { desk: avatars.desk, total: avatars.total, plus: (avatars.total -3) }
				var images = []
				var i = 0;
				_.each(avatars.images, function(a){
					if(i < 3) images.push(a);
					i++;
				});
				avs.images = images;
				return avs;
			}
		},
		deleteEvent(eventId, isParking){
			var event = { eventID: eventId };
			axios.post(`${server.baseURL}/calendar/delete`, event)
				.then(data =>
				{
					if(data.data.result){
						if(isParking){
							this.openParkingDeleted();
							this.userHasParkingBooked = false;
						}
						this.refreshEvents();
					}
				});
		},
		setPreset(presetID){
			switch(presetID){
				case 1: {
					this.startTime = "09:00";
					this.endTime = "17:00";
					this.$refs.startTime.value = "09:00";
					this.$refs.endTime.value = "17:00";
					break;
				}
				case 2: {
					this.startTime = "09:00";
					this.endTime = "13:00";
					this.$refs.startTime.value = "09:00";
					this.$refs.endTime.value = "13:00";
					break;
				}
			}
			this.checkTime();
		},
		onPress(e){
			if(e.key === "Shift")
				this.shiftPressed = true;
		},
		onRelease(e){
			if(e.key === "Shift")
				this.shiftPressed = false;
		},
		changeMap(){
			if(this.displayFullScreenMap)
				return "Dialog";
			return "Panel";
		},
		showHideFullScreen(){
			if(this.displayFullScreenMap){
				this.displayFullScreenMap = false;
				this.fullscreenIcon = "pi-window-maximize";
				this.fullscreenIconStyle = "";
			}
			else{
				this.displayFullScreenMap = true;
				this.fullscreenIcon = "pi-times";
				this.fullscreenIconStyle = "left: 1650px;"
			}
			var that = this;
			setTimeout(function (){ 
				that.fillDesks()}, 200);
		},
		fillDesks(){
			var that = this;
			if(!this.selectedDays || this.selectedDays.length == 0)
				return;
			console.log(this.$refs);
			var selectedDate = dayjs(this.selectedDays[this.selectedDays.length-1]);
			var selectedEvents = _.filter(this.events, function(e){
				return (dayjs(e.start).get('date') == selectedDate.get('date')) && (dayjs(e.start).get('month') == selectedDate.get('month')) 
				&& (dayjs(e.start).get('year') == selectedDate.get('year'));
			});
			console.log(selectedEvents);
			if(selectedEvents){
				//var that = this;
				for(var i = 1; i <= this.numDesks; i++){
					this.$refs['desk'+i].style.fill = this.rgbDefault;
				}
				_.each(this.deskFill, function(d){ d.fill = that.rgbDefault;});
				var totalHours = 0;
				_.each(selectedEvents, function(e){
					var fillback = '';
					totalHours += dayjs(e.end).diff(dayjs(e.start), 'hour', true);
					if(totalHours < 7.5){
						fillback = 'rgb(255, 255, 0, 0.5)';
					}
					else {
						fillback = 'rgb(255, 0, 0, 0.5)';
					}
					console.log(that.$refs['desk'+e.desk].style.fill);
					that.$refs['desk'+e.desk].style.fill = fillback;
					_.each(that.deskFill, function(d){ if(parseInt(e.desk) == d.desk) d.fill = fillback; })
					console.log("DESK", that.$refs['desk'+e.desk].style.fill);
					console.log(that.deskFill);
				})
				console.log(this.selectedDesks);
				if(this.selectedDesks.length > 0){
					_.each(this.selectedDesks, function(d){
						console.log(that.$refs['desk'+d]);
						that.$refs['desk'+d].style.fill = 'rgb(114, 192, 237, 0.5)';
					})
					
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.container {
		min-height: 50vh; // height of the browser viewport
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.loading {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5000;
	}

    .loading .loader {
        background-color: rgba(255, 255, 255, 0.5);
        display: block;
        height: 100px;
        left: 50%;
        position: relative;
        top: 50%;
        margin-left: -50px;
        margin-top: -50px;
        width: 100px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        text-align: center;
        line-height: 40px;
        color: #000000;
    }

		.loading .line-spin-fade-loader {
			left: 45%;
			position: absolute;
			top: 55%;
		}

		.line-spin-fade-loader {
			position: absolute;
			top: 50%;
			left: 50%;
		}

		.line-spin-fade-loader div {
			background-color: #000;
		}
		.fade-enter-active, .fade-leave-active {
			transition: opacity .5s;
		}
		.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
			opacity: 0;
		}
	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}
	
</style>
<style>
	.hide {
		display: none;
	}

	.hide2{
		visibility: hidden;
	}

	.show {
		display: block;
	}
	.centreSvg{
		display:block;
		margin:auto;
	}
	.containerSvg{
		height:200px;
        position: relative;
    }
    .box{
        width: 100%;
        height: 100%;            
        position: absolute;
        top: 0;
        left: 0;
    }
	.box2{        
        position: absolute;
        top: 0;
        left: 0;
    }
    .stack-top{
        z-index: 9;
		margin:auto;
		top:35%;
		left:25%;
    }
	.clip-circle img{
		clip-path: circle(26px at center) !important;
		width: 60px !important;
		height: 60px !important;
	}
	.confirmation-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.img-overlay-wrap {
        position: relative;
        display: inline-block; /* <= shrinks container to image size */
        transition: transform 150ms ease-in-out;
      }

      .img-overlay-wrap img { /* <= optional, for responsiveness */
         display: block;
         max-width: 100%;
         height: auto;
      }

      .img-overlay-wrap svg {
        position: absolute;
        top: 0;
        left: 0;
      }

	  .img-overlay-wrap i {
        position: absolute;
        top: 50px;
        left: 675px;
      }
	#dropShadowBlack{
	  background-color: rgba(0, 0, 0, 1);
   	  stroke-width: 1px;
	}

	.p-confirm-popup .p-confirm-popup-footer button {
		width:60px;
		height:40px;
		font-size: 15px;
	}
</style>
