<template>
<div>
<transition name="fade">
<div v-if="!isLoaded" class="loading" ref="loader">
        <div class="loader">
            Loading...
            <div class="pi pi-spin pi-spinner" style="fontSize: 3rem">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</transition>
<div>
	<div class="p-grid p-fluid dashboard" :class="{ hide2: !isLoaded}" >
		<div class="p-col-12">
			<Panel header="Parking Report">
                <div class="p-col-3">
                    <select class="p-field" v-model="selectedMonth">
                        <option v-for="month in months" :key="month.date" :value="month.date">{{month.text}}</option>
                    </select>
                </div>
                <div class="p-col-3">
                    <Button @click="getEvents(selectedMonth)" label="Generate Report"></Button>
                </div>
			</Panel>
		</div>
        <DataTable :value="events"  class="p-datatable-gridlines" :rows="10" dataKey="id" responsiveLayout="scroll" >
            <Column field="date" header="Date" style="min-width:12rem">
                <template #body="{data}">
                    <span class="p-column-title">Name</span>
                    {{formatDate(data.date)}}
                </template>
            </Column>
            <Column field="name" header="Name" style="min-width:12rem">
                <template #body="{data}">
                    <span class="p-column-title">Name</span>
                    {{data.user.first_name}} {{data.user.last_name}}
                </template>
            </Column>
            <Column field="card" header="Card Used" style="min-width:12rem">
                <template #body="{data}">
                    <span class="p-column-title">Name</span>
                    {{data.personal ? "Personal Card" : "Dorado Card " + (data.card ? data.card : "") }}
                </template>
            </Column>
        </DataTable>
	</div>
</div>
</div>
</template>

<script>
import { inject } from "vue";
import { server } from "@/helper";
import axios from "axios";
//import { _ } from "vue-underscore"
import dayjs from 'dayjs';
import customParseFormat from 'dayjs';

export default {
	data() {
		return {
			events: null,
            isLoaded: true,
			months: [],
			selectedMonth: null
		}
	},
	setup() {
		const Vue3GoogleOauth = inject("Vue3GoogleOauth");
		return {
			Vue3GoogleOauth,
		};
		
	},
	created() {
        dayjs.extend(customParseFormat);
		this.getMonths();
	},
	mounted() {
		var that = this;
		this.intervalCheck = setInterval(function(){
			if(that.Vue3GoogleOauth.isInit){
				that.checkLogin();
				clearInterval(that.intervalCheck);
			}
		}, 500);
	},
	methods: {
		checkLogin() {
			if(!this.Vue3GoogleOauth.isAuthorized){
				this.$router.push({ name: 'login' });
			}
			else{
				this.getEvents();
				this.isLoaded = true;
				this.viewDate = dayjs();
			}
		},
        getEvents(date) {
			var curDate = dayjs();
			if(date){
				curDate = dayjs(date);
			}
			var request = { email: this.$gAuth.instance.currentUser.get().getBasicProfile().getEmail(), currentDate: curDate };
            axios
                .post(`${server.baseURL}/calendar/getParkingsByMonth`, request)
                .then(data => { 
					if(data.data.isAdmin || data.data.isAccounts){
						this.events = data.data.parkings;
					}
					else{
						this.$router.push({ name: 'home' });
					}
				});
        },
		getTimeZone(){
			var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
			return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
		},
		formatDate(date){
			return dayjs(date).format('DD/MM/YYYY');
		},
		getMonths(){
			var today = dayjs();
			this.selectedMonth = today;
			var sixmonthsago = today.subtract(5, 'month');
			for(var i = 0; i < 6; i++){
				var date = sixmonthsago.add(i, 'month');
				var data = { date: date, text: date.format('MMMM YYYY')};
				this.months.push(data);
			}
			this.months = this.months.reverse();
		}
	}
}
</script>

<style lang="scss" scoped>
	.container {
		min-height: 50vh; // height of the browser viewport
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.loading {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5000;
	}

    .loading .loader {
        background-color: rgba(255, 255, 255, 0.5);
        display: block;
        height: 100px;
        left: 50%;
        position: relative;
        top: 50%;
        margin-left: -50px;
        margin-top: -50px;
        width: 100px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        text-align: center;
        line-height: 40px;
        color: #000000;
    }

		.loading .line-spin-fade-loader {
			left: 45%;
			position: absolute;
			top: 55%;
		}

		.line-spin-fade-loader {
			position: absolute;
			top: 50%;
			left: 50%;
		}

		.line-spin-fade-loader div {
			background-color: #000;
		}
		.fade-enter-active, .fade-leave-active {
			transition: opacity .5s;
		}
		.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
			opacity: 0;
		}
	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}
	
</style>
<style>
	.hide {
		display: none;
	}

	.hide2{
		visibility: hidden;
	}

	.show {
		display: block;
	}
	.centreSvg{
		display:block;
		margin:auto;
	}
	.containerSvg{
		height:200px;
        position: relative;
    }
    .box{
        width: 100%;
        height: 100%;            
        position: absolute;
        top: 0;
        left: 0;
    }
	.box2{        
        position: absolute;
        top: 0;
        left: 0;
    }
    .stack-top{
        z-index: 9;
		margin:auto;
		top:35%;
		left:25%;
    }
	.clip-circle img{
		clip-path: circle(20px at center);
	}
	.confirmation-content {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.img-overlay-wrap {
        position: relative;
        display: inline-block; /* <= shrinks container to image size */
        transition: transform 150ms ease-in-out;
      }

      .img-overlay-wrap img { /* <= optional, for responsiveness */
         display: block;
         max-width: 100%;
         height: auto;
      }

      .img-overlay-wrap svg {
        position: absolute;
        top: 0;
        left: 0;
      }
	#dropShadowBlack{
	  background-color: rgba(0, 0, 0, 1);
   	  stroke-width: 1px;
	}

	.p-confirm-popup .p-confirm-popup-footer button {
		width:60px;
		height:40px;
		font-size: 15px;
	}
</style>
