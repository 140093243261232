<template>
	<div :class="{ hide2: !Vue3GoogleOauth.isAuthorized}" class="layout-menu-container">
		<AppSubmenu ref="submenuRef" :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
import { inject } from "vue";
export default {
	props: {
		model: Array
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
    },
	components: {
		'AppSubmenu': AppSubmenu
	},
	setup() {
		const Vue3GoogleOauth = inject("Vue3GoogleOauth");
		return {
			Vue3GoogleOauth,
		};
		
	},
}
</script>

<style scoped>

</style>