<template>
	<ul v-if="items">
		<template v-for="(item,i) of items">
			<li v-if="visible(item) && !item.separator && !item.isAccounts" :key="i" :class="[{'active-menuitem': activeIndex === i && !item.to && !item.disabled}]" role="none">
				<div v-if="item.items && root===true" class='arrow'></div>
				<router-link v-if="item.to" :to="item.to" :class="[item.class, 'p-ripple',{'active-route': activeIndex === i, 'p-disabled': item.disabled}]" :style="item.style"
							@click="onMenuItemClick($event,item,i)" :target="item.target" exact role="menuitem" v-ripple>
					<i :class="item.icon"></i>
					<span>{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</router-link>
				<a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]"
					@click="onMenuItemClick($event,item,i)" :target="item.target" role="menuitem" v-ripple>
					<i :class="item.icon"></i>
					<span>{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</a>
				<transition name="layout-submenu-wrapper">
					<appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
				</transition>
			</li>
			<li v-if="item.isAccounts && showAccounts" :key="i" :class="[{'active-menuitem': activeIndex === i && !item.to && !item.disabled}]" role="none">
				<div v-if="item.items && root===true" class='arrow'></div>
				<router-link v-if="item.to" :to="item.to" :class="[item.class, 'p-ripple',{'active-route': activeIndex === i, 'p-disabled': item.disabled}]" :style="item.style"
							@click="onMenuItemClick($event,item,i)" :target="item.target" exact role="menuitem" v-ripple>
					<i :class="item.icon"></i>
					<span>{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</router-link>
				<a v-if="!item.to" :href="item.url||'#'" :style="item.style" :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]"
					@click="onMenuItemClick($event,item,i)" :target="item.target" role="menuitem" v-ripple>
					<i :class="item.icon"></i>
					<span>{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</a>
				<transition name="layout-submenu-wrapper">
					<appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
				</transition>
			</li>
			<li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
		</template>
		<li role="none" v-if="$store.state.monthsHoursLeft.length > 0">
			<a class='router-link-active router-link-exact-active p-ripple'  exact role="menuitem" aria-current="page">
				<span style="fontSize:20px;"> Hours Left: </span>
			</a>
		</li>
		
		<li v-for="month in $store.state.monthsHoursLeft" :key="month.month" role="none">
			<a class='router-link-active router-link-exact-active p-ripple' exact role="menuitem" aria-current="page">
				<span>{{month.month}} - {{month.hoursLeft.toFixed(2)}}</span>
			</a>
		</li>
		<li class="p-menu-separator" role="separator"></li>
	</ul>
</template>
<script>
//import { provide } from 'vue';
import { inject } from "vue";
import { server } from "@/helper";
import { _ } from "vue-underscore";
import axios from "axios";

export default {
	name: 'appsubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	setup(){
		const Vue3GoogleOauth = inject("Vue3GoogleOauth");
		return {
			Vue3GoogleOauth,
		};
	},
	mounted(){
		var that = this;
		this.intervalCheck = setInterval(function(){
			if(that.Vue3GoogleOauth.isInit){
				that.checkLogin();
				clearInterval(that.intervalCheck);
			}
		}, 500);
	},
	data() {
		return {
			activeIndex : null,
			showAccounts: false,
			roles: null
		}
	},
	methods: {
		checkLogin() {
			if(!this.Vue3GoogleOauth.isAuthorized){
				this.$router.push({ name: 'login' });
			}
			else{
				this.checkPermission();
			}
		},
		checkPermission() {
			var request = { userEmail: this.$gAuth.instance.currentUser.get().getBasicProfile().getEmail() };
			var that = this;
			axios
				.post(`${server.baseURL}/user-role/userRoles`, request)
				.then(data => { 
					if(data.data) that.roles = data.data;
					if(_.some(that.roles, function(r) {
						return r.roleID > 1;
					}))
					{
						this.showAccounts = true;
					}
				});
		},
		onMenuItemClick(event, item, index) {
			if (item.disabled) {
				event.preventDefault();
				return;
            }

            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
			}

			this.activeIndex = index === this.activeIndex ? null : index;

			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		},
		visible(item) {
			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		}
	},
	computed: {

	},
}
</script>

<style scoped>

</style>
