<template>
    <div>
        <div class="p-d-flex p-jc-center p-col-12">
            <img class="p-col-4" src="assets/layout/images/logo-large.png" alt="Dorado Games"/>
        </div>
        <div class="p-d-flex p-jc-center p-col-12">
            <Button @click="handleClickSignIn" :class="{ hide: Vue3GoogleOauth.isAuthorized }" :disabled="!Vue3GoogleOauth.isInit">Sign In</Button>
            <Button @click="handleClickSignOut" :class="{ hide: !Vue3GoogleOauth.isAuthorized }" :disabled="!Vue3GoogleOauth.isInit">Sign Out</Button>
        </div>
    </div>
</template>

<script>
import { inject } from "vue";
export default {
    data(){
        return {
            intervalCheck: null
        }
    },
    created() {
        var that = this;
		this.intervalCheck = setInterval(function(){
			if(that.Vue3GoogleOauth.isInit){
				that.checkLogin();
				clearInterval(that.intervalCheck);
			}
		}, 500);
    },
    methods: {
        checkLogin(){
            if(this.Vue3GoogleOauth.isAuthorized){
				this.$router.push({ name: 'home' });
			}
        },
        async handleClickSignIn(){
        try {
            const googleUser = await this.$gAuth.signIn();
            if (!googleUser) {
            return null;
            }
            this.user = googleUser.getBasicProfile().getEmail();
            if(!this.user.includes(process.env.VUE_APP_DOMAIN_FILTER)){
                await this.$gAuth.signOut();
                this.user = "";
            }
            else{
                this.$router.push({ name: 'home' });
            }
        } catch (error) {
            //on fail do something
            console.error(error);
            return null;
        }
        },

        // async handleClickGetAuthCode(){
        // try {
        //     const authCode = await this.$gAuth.getAuthCode();

        // } catch(error) {
        //     //on fail do something
        //     console.error(error);
        //     return null;
        // }
        // },

        async handleClickSignOut() {
        try {
            await this.$gAuth.signOut();
            this.user = "";
        } catch (error) {
            console.error(error);
        }
        },

        handleClickDisconnect() {
        window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
        },
    },
	setup() {
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");

        return {
        Vue3GoogleOauth
        };
  },
}
</script>
<style>

</style>
<style scoped lang="scss">
.hide {
    display: none;
}

.show {
    display: block;
}
.centreSvg{
    display:block;
    margin:auto;
}
.g-signin-button {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  background: #dd4b39;
}
.g-signin-button:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;

  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
}

.g-signin-button:hover,
.g-signin-button:focus {
  cursor: pointer;
  background: #e74b37;
}
</style>