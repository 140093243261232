<template>
	<div :class="{hide2: !isLoaded}" class="layout-profile">
		<div class="clip-circleProf">
			<img :src="profileImage" alt="" />
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{fullname}}</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <!-- <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
                <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li> -->
                <li><button class="p-link" @click="handleClickSignOut()"><i class="pi pi-fw pi-power-off"></i><span>Logout</span></button></li>
            </ul>
        </transition>
	</div>
</template>

<script>
import { inject } from "vue";

	export default {
		data() {
			return {
				expanded: false,
				profile: {},
				fullname: '',
				profileImage: '',
				isLoaded: false,
				intervalCheck: null
			}
		},
		setup() {
			const Vue3GoogleOauth = inject("Vue3GoogleOauth");
			return {
				Vue3GoogleOauth
			};
		},
		mounted() {
			var that = this;
			this.intervalCheck = setInterval(function(){
				if(that.Vue3GoogleOauth.isInit){
					that.checkLogin();
					clearInterval(that.intervalCheck);
				}
			}, 500);
		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			checkLogin(){
				if(this.Vue3GoogleOauth.isAuthorized){
					this.profile = this.$gAuth.instance.currentUser.get().getBasicProfile();
					this.fullname = this.profile.getName();
					this.profileImage = this.profile.getImageUrl();
					this.isLoaded = true;
				}
			},
			async handleClickSignOut() {
				try {
					await this.$gAuth.signOut();
					this.isLoaded = false;
					this.profile = {};
					this.fullname = "";
					this.profileImage = "";
					this.$router.push({ name: 'login' });
					this.expanded = false;
				} catch (error) {
					console.error(error);
				}
			}
		},
		watch: {
        $route() {
            var that = this;
			this.intervalCheck = setInterval(function(){
				if(that.Vue3GoogleOauth.isInit){
					that.checkLogin();
					clearInterval(that.intervalCheck);
				}
			}, 500);
        }
    },
	}
</script>

<style scoped>
.clip-circleProf img{
		clip-path: circle(25px at center);
	}
</style>